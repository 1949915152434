import Vue from 'vue';
/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	reklamaceData: {
		claim: {
			claimIdentifier: null,
			currentPage: null,
			iqosPassUuid: null,
			firstName: null,
			lastName: null,
			email: null,
			phoneCodes: null,
			phoneCode: {
				id: null,
				value: null,
				country: null,
			},
			phoneCodeId: null,
			phone: null,
			bankAccountNumber: null,
			street: null,
			houseNumber: null,
			city: null,
			postalCode: null,
			deliveryStreet: null,
			deliveryHouseNumber: null,
			deliveryCity: null,
			deliveryPostalCode: null,
			purchaseDate: null,
			receiptNumber: null,
			deviceModelId: null,
			deviceTypeId: null,
			deviceTasteId: null,
			numberOfPacks: null,
			serialNumber: null,
			storeTypeId: null,
			salesChainId: null,
			sellerNote: null,
			deviceStateId: null,
			deviceStateNote: null,
			claimResolvingTypeId: null,
			claimResolvingNote: null,
			claimResolvingFinalNote: null,
			defectTypeId: null,
			defectTypeNote: null,
			canProcessPersonalData: null,
			canRecycleDevice: null,
			canSendNotifications: null,
			isAcquaintedWithResult: null,
			deviceHandedOver: null,
			canSendToAddress: null,
			claimResolvingType: null,
			finalResolvingTypeId: null,
			solutionWay: null,
			timeframe: null,
			spiceIdValue: null,
			conceptCreatedAt: null,
			createdAt: null,
			defectType: null,
			deviceModels: null,
			deviceState: null,
			displayCategories: null,
			deviceNote: null,
			isEmailNotificationHandoverToPos: false,
			isEmailNotificationResolved: false,
			pos: null,
			status: null,
			storeTypes: null,
			updatedAt: null,
			deviceStates: null,
			claimResolvingTypes: null,
			defectTypes: null,
			claimResolvingFinalTypes: null,
			photos: [],
			handoverKind: null,
			trackingNumber: null,
			spareDeviceSerialNumber: null,
			user: {
				id: null,
				pmId: null,
				pos: null,
				username: null,
				fistname: null,
				lastname: null,
			},
			claimDiagnostics: {
				claimIdentifier: null,
				createdAt: null,
				creatorUser: null,
				decisionJustification: null,
				defectCause: [],
				defectCauseId: null,
				defectCauseNote: null,
				diagnosticsActNote: null,
				diagnosticsActs: null,
				diagnosticsActsIds: [],
				isDeviceStateAccepted: null,
				deviceState: null,
				deviceStateId: null,
				deviceStateNote: null,
				finisherUser: {
					firstname: null,
					id: null,
					lastname: null,
					pmId: null,
					pos: null,
					username: null,
				},
				recommendedSolutionWay: null,
				updatedAt: null,
			},
			mgmVouchers: null,
			selectedMgmVoucher: null,
			voucherIdentifier: null,
			voucherValue: null,
			isSendButtonDisabled: false,
			sendButtonTimeout: null,
			canSendVoucher: null,
			claimDevices: [],
		},
	},

	selectBoxes: {
		phoneCodes: null,
		deviceModels: null,
		storeTypes: null,
		deviceStates: null,
		claimResolvingTypes: null,
		defectTypes: null,
		defectCauses: null,
		claimResolvingFinalTypes: null,
		diagnosticActs: [],
		posTypes: null,
		posCategories: null,
		legalEntities: [],
	},

	alertMessage: [],

	dataGrid: {
		gridItems: {
			items: [],
			paginator: {},
			searchTerm: '',
		},

		gridOptions: {
			paginationPage: 1,
			showDataGrid: false,
			usedSearchBar: null,
			usedFiltering: false,
			usedFilterPreset: false,
			offsetNum: 0,
		},

		gridSingleItem: {
			claimIdentifier: '',
			status: '',
			message: '',
			deactivateMessage: '',
			isAnonymized: null,
			isIdr: false,
			firstName: null,
			lastName: null,
			email: null,
			deviceModel: null,
			deviceType: null,
			deviceTaste: null,
		},

		gridColumns: {
			claimId: false,
			phoneNumber: false,
			email: false,
			serialNumber: false,
			createdAt: false,
			status: false,
			deviceModel: false,
			deviceType: false,
			hiddenColumns: [],
			usedColumnSettings: false,
		},

		gridFilters: {
			claimIdentifier: null,
			phone: null,
			email: null,
			firstname: null,
			lastname: null,
			serialNumber: null,
			dateFrom: null,
			dateTo: null,
			statuses: [],
			canShowResolvedClaims: false,
			presetButtonStatuses: [],
			isEnteredInSf: null,
			deviceModel: null,
			deviceModelId: null,
			deviceType: null,
			deviceTypeId: null,
			showOnlyTypeFill: null,
		},
	},

	dataGridPoses: {
		gridItems: {
			items: [],
			paginator: {},
		},

		gridOptions: {
			paginationPage: 1,
			offsetNum: 0,
			usedFiltering: false,
			isDragAndDropEnabled: false,
		},

		gridSingleItem: {
			id: null,
			name: null,
			category: null,
			email: null,
			order: null,
			newGridPosition: null,
		},

		gridFilters: {
			id: null,
			name: null,
			category: null,
			email: null,
			order: null,
		},
	},

	poses: [],

	singlePos: {
		name: null,
		email: null,
		type: null,
		category: null,
		enabled: false,
		legalEntity: {
			id: null,
			legalInfo: null,
		},
	},

	editedLegalEntity: {
		id: null,
		legalInfo: null,
	},

	newLegalEntity: {
		legalInfo: null,
	},
});

export const mutations = {
	// UPDATE FIELDS ------------------------------------------------------------------------------------------------------
	UPDATE_FIELD (state, reklamaceData) {
		// toto nebude reaktivni
		// state.reklamaceData.claim[reklamaceData.fieldName] = reklamaceData.updatedValue;
		// protoze:
		// https://vuejs.org/v2/guide/reactivity.html
		// https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules

		// normalne, protoze sellerNote mate zadefinovanou uz tady ve state, by to fungovat melo,
		// nicmene api /claim/create (kterou vola action createReklamace a nasledne uklada do storu
		// CREATE_REKLAMACE) nevraci sellerNote (btw asi by melo, ne?) - sellerNote tedy pri
		// mutaci "state.reklamaceData = reklamace;" zmizi, pri volani UPDATE_FIELD se vyvori znovu,
		// a tady uz to musi dodrzovat pravidla popsana na odkazech vyse)

		// toto by taky fungovalo:
		// state.reklamaceData.claim = { ...state.reklamaceData.claim, [reklamaceData.fieldName]: reklamaceData.updatedValue };
		// nebo
		// state.reklamaceData.claim = Object.assign({}, state.reklamaceData.claim, { [reklamaceData.fieldName]: reklamaceData.updatedValue });
		// vue na to kazdopdne ma i vlastni metodu:
		Vue.set(state.reklamaceData.claim, reklamaceData.fieldName, reklamaceData.updatedValue);
	},

	ADD_ITEM_TO_ARRAY (state, item) {
		state.reklamaceData.claim.claimDevices.push(item);
	},

	PURGE_CLEAN_DEVICES (state) {
		state.reklamaceData.claim.claimDevices = [];
	},

	DELETE_ITEM_FROM_ARRAY (state, itemId) {
		const index = state.reklamaceData.claim.claimDevices.findIndex(item => item.id === itemId);
		if (index !== -1) {
			state.reklamaceData.claim.claimDevices.splice(index, 1);
		}
	},

	UPDATE_SERIAL_NUMBER_IN_ARRAY (state, payload) {
		const itemNew = state.reklamaceData.claim.claimDevices.find(item => item.id === payload.id);
		if (itemNew) {
			itemNew.serialNumber = payload.value;
		}
	},

	UPDATE_DEVICE_TASTE_IN_ARRAY (state, payload) {
		const itemNew = state.reklamaceData.claim.claimDevices.find(item => item.id === payload.id);
		if (itemNew) {
			itemNew.deviceTasteId = payload.value;
		}
	},

	UPDATE_FIELD_GLOBAL (state, payload) {
		Vue.set(state[payload.storeObject], payload.fieldName, payload.updatedValue);
	},

	UPDATE_FIELD_SELECTBOXES (state, selectBoxesData) {
		Vue.set(state.selectBoxes, selectBoxesData.fieldName, selectBoxesData.updatedValue);
	},

	UPDATE_FIELD_CLAIM_DIAGNOSTICS (state, claimDiagnostics) {
		// state.diagnosticsData[diagnosticsData.fieldName] = diagnosticsData.updatedValue;
		Vue.set(state.reklamaceData.claim.claimDiagnostics, claimDiagnostics.fieldName, claimDiagnostics.updatedValue);
	},

	CREATE_REKLAMACE (state, reklamace) {
		state.reklamaceData = reklamace;
	},

	UPDATE_ALL_REKLAMACE_FIELDS (state, payload) {
		for (const value in payload) {
			if (payload.hasOwnProperty(value)) {
				state.reklamaceData.claim[value] = payload[value];
			}
		}
	},

	UPDATE_ALL_DIAGNOSTICS_FIELDS (state, payload) {
		for (const value in payload) {
			if (payload.hasOwnProperty(value)) {
				state.reklamaceData.claim.claimDiagnostics[value] = payload[value];
			}
		}
	},

	UPDATE_ALL_SINGLE_POS_FIELDS (state, payload) {
		for (const value in payload) {
			if (payload.hasOwnProperty(value)) {
				state.singlePos[value] = payload[value];
			}
		}
	},

	// Update funkce pro celý dataGrid objekt
	UPDATE_DATA_GRID_VALUES (state, payload) {
		Vue.set(state.dataGrid[payload.gridObject], payload.fieldName, payload.updatedValue);
	},

	// Switch pro přidávání a oddělávání z pole schovaných sloupců
	ADD_HIDDEN_COLUMNS (state, payload) {
		let hiddenColumns = state.dataGrid.gridColumns.hiddenColumns;
		if (hiddenColumns.includes(payload)) {
			hiddenColumns.splice(hiddenColumns.indexOf(payload), 1);
		}
		else {
			hiddenColumns.push(payload);
		}
	},

	// Přidání sloupce do pole - vytvořeno kvůli defaultně schovaným sloupcům
	ADD_HIDDEN_COLUMN (state, payload) {
		let hiddenColumns = state.dataGrid.gridColumns.hiddenColumns;
		if (hiddenColumns.includes(payload)) {
			return;
		}
		else {
			hiddenColumns.push(payload);
		}
	},

	UPDATE_FIELD_POSES (state, payload) {
		state.poses = payload;
	},

	// Update funkce pro celý dataGrid objekt
	UPDATE_DATA_GRID_POS_VALUES (state, payload) {
		Vue.set(state.dataGridPoses[payload.gridObject], payload.fieldName, payload.updatedValue);
	},

	// END UPDATE FIELDS ------------------------------------------------------------------------------------------------------

	// ALERT MESSAGE ------------------------------------------------------------------------------------------------------

	ADD_ALERT_MESSAGE (state, message) {
		state.alertMessage.push(message);
	},

	DELETE_ALERT_MESSAGE (state, { message }) {
		state.alertMessage.splice(state.alertMessage.indexOf(message), 1);
	},

	// END ALERT MESSAGE ------------------------------------------------------------------------------------------------------

	// DATA GRID ------------------------------------------------------------------------------------------------------

	ADD_DATA_GRID (state, payload) {
		for (const value in payload) {
			if (payload.hasOwnProperty(value)) {
				state.dataGrid.gridItems[value] = payload[value];
			}
		}
	},

	// END DATA GRID ------------------------------------------------------------------------------------------------------

	// RESET VALUES ------------------------------------------------------------------------------------------------------
	RESET_CLAIM (state) {
		for (const value in state.reklamaceData.claim) {
			if (state.reklamaceData.claim.hasOwnProperty(value)) {
				state.reklamaceData.claim[value] = null;
			}
		}
	},

	RESET_DIAGNOSTICS (state) {
		for (const value in state.reklamaceData.claim.claimDiagnostics) {
			if (state.reklamaceData.claim.claimDiagnostics.hasOwnProperty(value)) {
				state.reklamaceData.claim.claimDiagnostics[value] = null;
			}
		}
	},

	RESET_GRID_FILTERS (state) {
		for (const value in state.dataGrid.gridFilters) {
			if (state.dataGrid.gridFilters.hasOwnProperty(value)) {
				state.dataGrid.gridFilters[value] = null;
			}
		}
	},

	RESET_GRID_POS_FILTERS (state) {
		for (const value in state.dataGridPoses.gridFilters) {
			if (state.dataGridPoses.gridFilters.hasOwnProperty(value)) {
				state.dataGridPoses.gridFilters[value] = null;
			}
		}
	},

	RESET_GRID_ITEMS (state, gridObject) {
		for (const value in state.dataGrid[gridObject]) {
			if (state.dataGrid[gridObject].hasOwnProperty(value)) {
				state.dataGrid[gridObject][value] = null;

				if (state.dataGrid.gridColumns) {
					state.dataGrid.gridColumns.hiddenColumns = [];
				}
			}
		}
	},

	RESET_SINGLE_POS_ITEMS (state) {
		for (const value in state.singlePos) {
			if (state.singlePos.hasOwnProperty(value)) {
				state.singlePos[value] = null;

				state.singlePos.legalEntity = { id: null, legalInfo: null };
			}
		}
	},

	RESET_LEGAL_ENTITY (state) {
		state.newLegalEntity = { legalInfo: null };
		state.editedLegalEntity = { id: null, legalInfo: null };
	},

	DELETE_PHOTO_CLAIM (state, id) {
		let photos;
		let index;

		photos = state.reklamaceData.claim.photos;

		index = photos.map(item => item.id).indexOf(id);

		photos.splice(index, 1);
	},

	DELETE_PHOTO_DIAGNOSTICS (state, id) {
		let photos;
		let index;

		photos = state.reklamaceData.claim.claimDiagnostics.photos;

		index = photos.map(item => item.id).indexOf(id);

		photos.splice(index, 1);
	},

	// END RESET VALUES ------------------------------------------------------------------------------------------------------

	ADD_PHOTO (state, photo) {
		state.reklamaceData.claim.photos.push(photo);
	},
};

export const actions = {
	updateField ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD', { fieldName, updatedValue });
	},

	updateFieldSelecboxes ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD_SELECTBOXES', { fieldName, updatedValue });
	},

	updateFieldClaimDiagnostics ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD_CLAIM_DIAGNOSTICS', { fieldName, updatedValue });
	},

	updateAllReklamaceFields ({ commit }, payload) {
		commit('UPDATE_ALL_REKLAMACE_FIELDS', payload);
	},

	updateAllDiagnosticsFields ({ commit }, payload) {
		commit('UPDATE_ALL_DIAGNOSTICS_FIELDS', payload);
	},

	createReklamace ({ commit }) {
		return this.$axios.get('/claim/create').then((response) => {
			commit('CREATE_REKLAMACE', response.data.payload);
		});
	},

	addDatagrid ({ commit }, payload) {
		commit('ADD_DATA_GRID', payload);
	},

	flashMessage ({ commit }, payload) {
		commit('ADD_ALERT_MESSAGE', payload);

		setTimeout(function () {
			commit('DELETE_ALERT_MESSAGE', payload);
		}, payload.duration ? payload.duration : 3000);
	},

	getDisplayCategories ({ state, commit }) {
		let deviceModelId = state.reklamaceData ? state.reklamaceData.claim.deviceModelId : null;
		let deviceTypeId = state.reklamaceData ? state.reklamaceData.claim.deviceTypeId : null;
		// console.log(deviceModelId, deviceTypeId);
		let deviceModels = state.selectBoxes ? state.selectBoxes.deviceModels.filter(item => item.id === deviceModelId) : null;

		// Pokud je vybraný select deviceModel Jiné, ulož jeho displayCategoriesCodes do store
		if (deviceModels[0] !== undefined && deviceModels[0].isOther === true) {
			let deviceModelDisplayCategories = deviceModels[0].displayCategoriesCodes;
			commit('UPDATE_FIELD', { fieldName: 'displayCategories', updatedValue: deviceModelDisplayCategories });
			return;
		}

		// Pokud je vybraný select deviceModel a není vybraný deviceType (deviceTypeId === null), tak ukonči funkci
		if (deviceModelId && deviceTypeId === null) {
			return;
		}
		// console.log(deviceModels);
		// setTimeout(() => {
		// 	console.log(deviceModels);
		// }, 5000);
		let deviceType = deviceModels.length > 0 ? deviceModels[0].deviceTypes.find(item => item.id === deviceTypeId) : null;
		if (deviceTypeId === null) {
			return;
		}
		let deviceModelDisplayCategories = deviceModels !== null && deviceModels.length > 0 ? deviceModels[0].displayCategoriesCodes : null;
		let deviceTypeDisplayCategories = deviceType !== null ? deviceType.displayCategoriesCodes : null;

		let displayCategories = deviceModelDisplayCategories !== null && deviceTypeDisplayCategories !== null ? deviceModelDisplayCategories.concat(deviceTypeDisplayCategories) : null;

		commit('UPDATE_FIELD', { fieldName: 'displayCategories', updatedValue: displayCategories });

		// console.log(displayCategories);
	},

	checkMaxLengthOfDeviceType ({ state, commit }) {
		let thisDeviceModel = state.selectBoxes ? state.selectBoxes.deviceModels.find(deviceModel => deviceModel.id === state.reklamaceData.claim.deviceModelId) : null;
		if (thisDeviceModel && thisDeviceModel.isOther) {
			commit('UPDATE_FIELD', { fieldName: 'serialNumberMaxLength', updatedValue: thisDeviceModel.serialNumberLength });
			return;
		}
		let foundDeviceTypes = thisDeviceModel ? thisDeviceModel.deviceTypes : null;
		let foundDeviceType = foundDeviceTypes !== null && foundDeviceTypes.length > 0 ? foundDeviceTypes.find(deviceType => deviceType.id === state.reklamaceData.claim.deviceTypeId) : null;
		let foundDeviceTypeSerialNumberLength = foundDeviceType !== null ? foundDeviceType.serialNumberLength : null;

		commit('UPDATE_FIELD', { fieldName: 'serialNumberMaxLength', updatedValue: foundDeviceTypeSerialNumberLength });
	},

	// Pokud má uživatel roli claimPos, tak musí mít defaultně schované sloupce Model a Typ zařízení
	// Přidá názvy class sloupců do pole a změní hodnotu checkboxů na true
	hideGridColumnsForPos ({ commit }) {
		commit('ADD_HIDDEN_COLUMN', 'table-device-model');
		commit('ADD_HIDDEN_COLUMN', 'table-device-type');
		commit('UPDATE_DATA_GRID_VALUES', { gridObject: 'gridColumns', fieldName: 'deviceModel', updatedValue: true });
		commit('UPDATE_DATA_GRID_VALUES', { gridObject: 'gridColumns', fieldName: 'deviceType', updatedValue: true });
	},

	// Funkce, která dá disabled na button odeslání voucheru na 30 sekund
	startSendButtonCountdown ({ commit }) {
		commit('UPDATE_FIELD', { fieldName: 'isSendButtonDisabled', updatedValue: true });

		// Zapni timer na 30 sekund a poté znovu zaktivni button na odeslání voucheru
		setTimeout(() => {
			commit('UPDATE_FIELD', { fieldName: 'isSendButtonDisabled', updatedValue: false });
		}, 30000);
	},

	// Pro CreateClaim3.vue - pokud se jedná o Slovensko, tak to má jen možnost využití handoverKind pos, takže po načtení této komponenty musí být v selectu předvybraný option pos
	preselectHandoverKindForSlovakia ({ commit }) {
		if (this.$i18n.locale == 'sk') {
			commit('UPDATE_FIELD', { fieldName: 'handoverKind', updatedValue: 'pos' });
		}
	},

	addPhoto ({ commit }, payload) {
		commit('ADD_PHOTO', payload);
	},
};


